<template>
  <v-container>
    <v-row justify="end">
      <v-col cols="12" md="3" class="text-right">
        <v-btn
          small
          outlined
          class="mr-3"
          @click="editStore($route.params.busId)"
          :color="$store.state.primaryColor"
        >
          {{ $t("edit") }}
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn
          small
          outlined
          class="mr-3"
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
        >
          {{ $t("delete") }}
          <v-icon small>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card dark>
        <v-card-title class="justify-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete") }}
            <span class="font-weight-medium"> {{ details.businessName }}</span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            @click="deleteStore"
          >
            {{ $t("confirm delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      shaped
      top
      rounded="pill"
      v-model="snackbar"
      :timeout="timeout"
      color="blue-grey"
    >
      {{ $t("deleted") }}!

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <v-card class="mx-auto" max-width="344">
                <v-card-title class="body-2">
                  {{ details.abbreviation }}{{ $t("logo") }}
                </v-card-title>
                <v-img :src="details.logo" height="150px"></v-img>
              </v-card>
              <v-card class="mx-auto mt-2" max-width="344">
                <v-card-title class="body-2">
                  {{ details.abbreviation }} {{ $t("business banner") }}
                </v-card-title>
                <v-img :src="details.banner" height="150px"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" md="9">
              <v-card>
                <v-container class="caption">
                  <v-row>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("business name") }} :
                      </span>
                      <span>{{ details.businessName }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("type of business") }}:
                      </span>
                      <span class="">{{ details.businessType }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold">{{ $t("email") }}: </span>
                      <span class="">{{ details.email }} </span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("telephone") }}:
                      </span>
                      <span class="">{{ details.phone }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("website") }}:
                      </span>
                      <span class="">{{ details.website }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("PO BOX") }}:
                      </span>
                      <span>{{ details.address.pobox }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("registration number") }}:
                      </span>
                      <span class="">{{ details.regNo }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("permit number") }}:
                      </span>
                      <span class="">{{ details.permitNo }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold">{{ $t("pin") }}: </span>
                      <span class="">{{ details.pin }} </span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold">{{ $t("glopay") }}: </span>
                      <span class="">{{ details.glopay }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("bank name") }}:
                      </span>
                      <span class="">{{ details.bank.bankName }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("bank account number") }}:
                      </span>
                      <span class="">{{ details.bank.accountNo }} </span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("mpesa till number") }}:
                      </span>
                      <span class="">{{ details.mpesa.tillNo }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("mpesa paybill number") }}:
                      </span>
                      <span class="">{{ details.mpesa.paybillNo }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("mpesa account number") }}:
                      </span>
                      <span class="">{{ details.bank.accountNo }} </span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("country") }}:
                      </span>
                      <span class="">{{ details.address.country }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold">{{ $t("county") }}: </span>
                      <span class="">{{ details.address.county }}</span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold">{{ $t("city") }}: </span>
                      <span class="">{{ details.address.city }} </span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("street") }}:
                      </span>
                      <span>{{ details.address.street }} </span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold">{{ $t("estate") }}: </span>
                      <span>{{ details.address.estate }}</span>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-bold"
                        >{{ $t("building") }}:
                      </span>
                      <span
                        >{{ details.address.building }},
                        {{ details.address.no }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row
      v-show="pleaseWait"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="text-subtitle-1 text-center" cols="12">
        {{ $t("loading... please wait") }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          :color="$store.state.primaryColor"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
// import { add } from 'date-fns';

export default {
  data: () => ({
    details: "",
    snackbar: false,
    timeout: 2000,
    delDialog: false,
    pleaseWait: true,
  }),

  created() {
    this.getStoreDetails();
  },

  methods: {
    getStoreDetails() {
      db.collection("businesses")
        .doc(this.$route.params.busId)
        .get()
        .then((doc) => {
          this.details = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },

    editStore(busID) {
      this.$router.push({ name: "StoreEdit", params: { busId: busID } });
    },

    deleteStore() {
      db.collection("businesses")
        .doc(this.$route.params.busId)
        .delete()
        .then(() => {
          this.snackbar = true;
          setTimeout(() => {
            window.history.back();
          }, 2000);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
  },
};
</script>

<style></style>
